<template>
  <div class="w-full h-full pt-5">
    <div class="flex">
      <back-button
        label="Back"
        @onClick="$router.push({ name: 'Functions' })"
        variant="secondary"
        class="mr-4"
      />
      <div class="mt-4" style="border-left: 1px solid #878E99; height:1.5rem" />
      <h1 class="text-xl  text-left font-extrabold ml-4 mt-3">
        Org Structure
      </h1>
    </div>
    <div class="mt-4 ml-10" v-if="!loading">
      <h1 style="font-size:10px; width:189px">
        This diagram displays the hierarchy of functions within your
        organization.
      </h1>
    </div>

    <div style="height:100%" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>

    <org-chart
      class="-mt-12"
      v-else
      :toggle-siblings-resp="true"
      @node-click="handleClick($event)"
      :datasource="ds"
    >
      <template slot-scope="{ nodeData }">
        <div class>
          <div class="font-bold" style="font-size:10px">
            {{ nodeData.name }}
          </div>
        </div>
      </template>
    </org-chart>
    <RightSideBar
      v-if="dialog"
      @close="dialog = false"
      :close-button="true"
      :loading="loading"
      button-class="bg-dynamicBackBtn text-white"
    >
      <div class="dialog pb-12 mt-12">
        <div class="flex mb-6 ml-12">
          <div class="font-bold text-sm">
            <h1 class="pb-6">Function Name</h1>
            <h1 class="pb-6">Hierarchy</h1>
            <h1 class="pb-6">Supervisory Function</h1>
            <h1 class="pb-6">Cost Center</h1>
            <h1 class="pb-6">KPI Summary</h1>
          </div>
          <div class="ml-8 text-sm">
            <h1 class="pb-6">
              {{ dialogData.name ? dialogData.name : "Nil" }}
            </h1>
            <h1 class="pb-6">
              {{ dialogData.hierarchy ? dialogData.hierarchy.name : "Nil" }}
            </h1>
            <h1 class="pb-6">
              {{ dialogData.reportsTo ? dialogData.reportsTo.name : "Nil" }}
            </h1>
            <h1 class="pb-6">
              {{ dialogData.costCenter ? dialogData.costCenter : "Nil" }}
            </h1>
            <h1 class="pb-6">
              <ul v-if="dialogData.kpis" class style="list-style-type:disc;">
                <li
                  v-for="(item, index) in dialogData.kpis"
                  class="text-sm"
                  :key="index"
                >
                  {{ item.name }}
                </li>
              </ul>
            </h1>
          </div>
        </div>
      </div>
    </RightSideBar>
  </div>
</template>

<script>
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import RightSideBar from "@/components/RightSideBar";
import OrgChart from "./orgChart/OrganizationChartContainer";

export default {
  name: "OrgStructure",
  components: { OrgChart, RightSideBar, BackButton },
  data() {
    return {
      ds: [],
      loading: true,
      dialog: false,
      dialogData: {}
    };
  },
  computed: {},
  watch: {},
  methods: {
    handleClick(event) {
      this.dialog = true;
      this.dialogData = event;
    }
  },
  mounted() {
    this.$_getOrgStructure().then(result => {
      // eslint-disable-next-line prefer-destructuring
      this.ds = result.data.orgStructure[0];
      this.loading = false;
    });
  }
};
</script>

<style>
.dialog {
  background: #f9f9f9;
  width: 485px;
  border-radius: 8px;
}
</style>
